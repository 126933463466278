import {gsap} from "gsap";

export default class PageTransition {
    constructor() {
        // this.urls = document.querySelectorAll('[data-transition="true"]')
        this.animationOutContainer = document.querySelector('.animation-out-container')
        this.animationInContainer = document.querySelector('.animation-in-container')
    }
    
    animationOut(url) {
        const self = this
        
        const path = self.animationOutContainer.querySelector(".pathOut")
        const start = "M 0 100 V 50 Q 50 0 100 50 V 100 z"
        const end = "M 0 100 V 0 Q 50 0 100 0 V 100 z"
        const hrefAttr = url.getAttribute('href')
        const animationOutTl = gsap.timeline()
        
        animationOutTl.to(path, 0.8, {
            attr: {
                d: start
            },
            ease: "power4.in"
        })
        animationOutTl.to(path, 0.4, {
            attr: {
                d: end
            },
            ease: "power4.out",
            onComplete: function () {
                document.location.href = hrefAttr
            }
        })
    }
    
    animationIn() {
        const self = this
        const animateInTl = gsap.timeline()
        
        animateInTl.to(self.animationInContainer, 2, {
            scaleY: '0',
            ease: "power4.out"
        })
        
        
    }
}

