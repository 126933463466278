import './scripts/about'
import PageTransition from './scripts/general/pageTransitions'

// page transitions

const allUrls = document.querySelectorAll('[data-transition="true"]')
allUrls.forEach(url => {
    url.addEventListener('click', e => {
        e.preventDefault()
        const animationOut = new PageTransition()
        animationOut.animationOut(url)
    })
})

window.onload = function () {
    console.log(document.referrer)
    const animationIn = new PageTransition()
    animationIn.animationIn()
}

module.exports = {
    "minifySvg": false
  }